/*
 *
 *  records
 *
 */

import actions from './actions';
import constants from './constants';
import reducer from './reducer';
import sagas from './sagas';
import remotes from './remotes';
import selectors from './selectors';

/**
 * @param {string} name 
 * @param {array} remotesBlock 
 */
export default function records(name, additionalBlock = {}, remotesBlock = []) {
  const {
    constants: additionalConstants,
    actions: additionalActions,
    remotes: additionalRemotes,
    selectors: additionalSelectors,
    saga: AdditionalSaga = function* additionalSaga() { }
  } = additionalBlock;

  const actualConstants = constants(name);
  const actualActions = actions(actualConstants);
  const actualRemotes = remotes(name, remotesBlock);
  const actualReducer = reducer(actualConstants, name, additionalConstants);
  const actualSelectors = selectors(name);
  let actualSagas = sagas(actualConstants, actualActions, actualRemotes, actualSelectors, name, AdditionalSaga);

  return {
    name,
    actions: Object.assign({}, actualActions, additionalActions),
    constants: Object.assign({}, actualConstants, additionalConstants),
    reducer: actualReducer,
    saga: actualSagas,
    selectors: Object.assign({}, actualSelectors, additionalSelectors),
    remotes: Object.assign({}, actualRemotes, additionalRemotes)
  };
}

