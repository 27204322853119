/*
 *
 * Session actions
 *
 */

import {
  VERIFY_SESSION,
  VERIFY_SESSION_SUCCESS,
  VERIFY_SESSION_ERROR,
  LOG_IN,
  LOG_IN_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT,
  LOG_OUT_SUCCESS,
  LOG_OUT_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  VERIFY_OTP,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_ERROR,
  NOTIFICATION,
  NOTIFICATION_SUCCESS,
  NOTIFICATION_ERROR,
  CLEAR_CACHE,
  UPLOAD_FILES
} from './constants';

/**
 * @param {string} secret 
 */
export function verifySession(secret, accType) {
  return {
    type: VERIFY_SESSION,
    secret,
    accType
  };
}

/**
 * @param {object} user 
 */
export function verifySessionSuccess(user) {
  return {
    type: VERIFY_SESSION_SUCCESS,
    user
  };
}

/**
 * @param {string} error 
 */
export function verifySessionError(error) {
  return {
    type: VERIFY_SESSION_ERROR,
    error,
  };
}

/**
 * 
 * @param {object} record 
 * @param {function} setSubmitting 
 * @param {function} redirect 
 * @returns 
 */
export function logIn(record, setSubmitting, redirect) {
  return {
    type: LOG_IN,
    record,
    setSubmitting,
    redirect
  };
}

/**
 * @param {string} token 
 */
export function logInSuccess(token) {
  return {
    type: LOG_IN_SUCCESS,
    token
  };
}

/**
 * @param {string} error 
 */
export function logInError(error) {
  return {
    type: LOG_IN_ERROR,
    error,
  };
}

export function logOut(redirect) {
  return {
    type: LOG_OUT,
    redirect
  };
}

export function logOutSuccess() {
  return {
    type: LOG_OUT_SUCCESS,
  };
}

/**
 * @param {string} error 
 */
export function logOutError(error) {
  return {
    type: LOG_OUT_ERROR,
    error,
  };
}

/**
 * 
 * @param {object} record 
 * @param {function} setSubmitting 
 * @param {function} redirect 
 * @returns 
 */
export function forgotPassword(record, setSubmitting, redirect) {
  return {
    type: FORGOT_PASSWORD,
    record,
    setSubmitting,
    redirect
  };
}

/**
 * @param {string} success 
 */
export function forgotPasswordSuccess(success) {
  return {
    type: FORGOT_PASSWORD_SUCCESS,
    success
  };
}

/**
 * @param {string} error 
 */
export function forgotPasswordError(error) {
  return {
    type: FORGOT_PASSWORD_ERROR,
    error,
  };
}


/**
 * 
 * @param {object} record 
 * @param {function} setSubmitting 
 * @param {function} redirect 
 * @returns 
 */
export function changePassword(record, setSubmitting, redirect) {
  return {
    type: CHANGE_PASSWORD,
    record,
    setSubmitting,
    redirect
  };
}

/**
 * @param {string} success 
 */
export function changePasswordSuccess(success) {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    success
  };
}

/**
 * @param {string} error 
 */
export function changePasswordError(error) {
  return {
    type: CHANGE_PASSWORD_ERROR,
    error,
  };
}


/**
 * 
 * @param {string} otp 
 * @param {function} setSubmitting 
 * @param {function} redirect 
 * @returns 
 */
export function verifyOtp(record, setSubmitting, redirect) {
  return {
    type: VERIFY_OTP,
    record,
    setSubmitting,
    redirect
  };
}


/**
 * @param {string} token 
 */
export function verifyOtpSuccess(token) {
  return {
    type: VERIFY_OTP_SUCCESS,
    token
  };
}

/**
 * @param {string} error 
 */
export function verifyOtpError(error) {
  return {
    type: VERIFY_OTP_ERROR,
    error
  };
}


export function notification() {
  return {
    type: NOTIFICATION
  };
}


/**
 * @param {Array} records 
 */
export function notificationSuccess(records) {
  return {
    type: NOTIFICATION_SUCCESS,
    records
  };
}

/**
 * @param {string} error 
 */
export function notificationError(error) {
  return {
    type: NOTIFICATION_ERROR,
    error
  };
}

export function clearCache() {
  return {
    type: CLEAR_CACHE
  };
}


export function uploadFiles(files, uploadUrl, setUpload, setValues, setError) {
  return {
    type: UPLOAD_FILES,
    files,
    uploadUrl,
    setUpload,
    setValues,
    setError
  };
}


export default {
  verifySession,
  verifySessionSuccess,
  verifySessionError,
  logIn,
  logInSuccess,
  logInError,
  logOut,
  logOutSuccess,
  logOutError,
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordError,
  changePassword,
  changePasswordSuccess,
  changePasswordError,
  verifyOtp,
  verifyOtpSuccess,
  verifySessionError,
  notification,
  notificationSuccess,
  notificationError,
  clearCache
}