
import { lazy } from 'react';

//Schema
import schema from "../schema"

// Authentication related pages
const Login = lazy(() => import('pages/Authentication/Login'));
const Logout = lazy(() => import('pages/Authentication/Logout'));
const ForgotPassword = lazy(() => import('pages/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/Authentication/ResetPassword'));
const Verification = lazy(() => import('pages/Authentication/Verification'));

// Dashboard Page
const Dashboard = lazy(() => import('pages/Dashboard'));

// NotFound Page
const NotFoundPage = lazy(() => import('pages/NotFound'));


const additionalRoutes = (schema || []).filter(_ => _.visible);

const routes = [
  { name: 'dashboard', path: "/", component: Dashboard },
  { name: 'logout', path: "/logout", component: Logout },
  { name: 'login', path: "/:type/login", component: Login },
  { name: 'forgot-password', path: "/:type/forgot-password", component: ForgotPassword },
  { name: 'reset-password', path: "/:type/reset-password", component: ResetPassword },
  { name: 'verify', path: "/:type/verify", component: Verification },
  { name: 'notfound', path: "*", component: NotFoundPage },
  ...additionalRoutes
]
export default routes;
