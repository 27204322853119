/*
 *
 *  selectors
 *
 */

import { createSelector } from 'reselect';
import mapRecords from './utils';
import { selectSession } from '../session/selectors';
/**
 * @param {string} name 
 */
export default function selectors(name) {
  const selectDomain = () => (state) => state[name] || false;

  const selectLoading = () => createSelector(
    selectDomain(),
    (domain) => domain && domain.loading || false,
  );

  const selectRecordsMetaData = () => createSelector(
    selectDomain(),
    selectSession(),
    (domain, sessionState) => (domain && domain.recordsMetaData && Object.assign({}, domain.recordsMetaData, { user: sessionState && sessionState.user || {} })) || {},
  );

  const selectRecords = () => createSelector(
    selectDomain(),
    (domain) => domain && domain.records && mapRecords(domain.records, name) || [],
  );

  const selectRecord = (id) => createSelector(
    selectDomain(),
     (domain) => domain && domain.records && mapRecords(domain.records, name).find(_ => _.id.toString() === id.toString()) || {}
  );

  const selectError = () => createSelector(
    selectDomain(),
    (domain) => (domain && domain.error) || false,
  );

  const selectSuccess = () => createSelector(
    selectDomain(),
    (domain) => (domain && domain.success) || false,
  );

  const selectUpdateTimestamp = () => createSelector(
    selectDomain(),
    (domain) => (domain && domain.lastUpdate) || false,
  );

  const selectParams = () => createSelector(
    selectDomain(),
    (domain) => (domain && domain.params) || {},
  )

  const selectTotalItems = () => createSelector(
    selectDomain(),
    (domain) => (domain && domain.total_items) || 0,
  )

  const selectCurrentPage = () => createSelector(
    selectDomain(),
    (domain) => (domain && domain.current_page) || 1,
  )

  return {
    selectDomain,
    selectLoading,
    selectRecords,
    selectRecordsMetaData,
    selectRecord,
    selectError,
    selectSuccess,
    selectUpdateTimestamp,
    selectParams,
    selectTotalItems,
    selectCurrentPage
  };
}
