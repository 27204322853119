/**
 * 
 * Upload Remotes
 * 
 */

import api from '../../helpers/api'

export async function uploadFile(files) {
    try {
        const config = { headers: { "content-type": "multipart/form-data" } };
        let formData = new FormData();

        let i = 0;
        Object.values(files).forEach(file => {
            i = i + 1
            let newFileName = 'USR' + i + '.' + file.name.split('?')[0].split('.').pop();
            formData.append('files', file, newFileName);
        });

        let response = await api.post('/image/upload', formData, config);
        if (response.status === 200) {
            return response.data
        }
    }
    catch (err) {
        return Promise.reject(err) // Return rejected promise with the caught error
    }

    // try {
    //     let response = await api.post(upload_url, { file_name, content_type })
    //     if (file) {
    //         response.data.file = file
    //     }
    //     return response.data
    // }
    // catch (err) {
    //     return Promise.reject(err) // Return rejected promise with the caught error
    // }
}
// return await api.post(upload_url, {
//     file_name,
//     content_type
// }).then((response) => file ? Object.assign({}, response.data, { file: file }) : response.data).catch((error) => Promise.reject(error))


export async function uploadS3File(file, content_type, upload_url, public_url) {
    return await fetch(upload_url, {
        method: 'PUT',
        headers: { 'Content-Type': content_type },
        body: file
    }).then(() => Object.assign({}, { public_url })).catch((error) => Promise.reject(error))
}

export default {
    uploadFile,
    uploadS3File
}