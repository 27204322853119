
/*
 *
 *  sagas
 *
 */


import { call, take, put, race, select, all, delay } from 'redux-saga/effects';
import _ from 'lodash';
import moment from 'moment';
import { selectUser } from 'store/session/selectors';

export default function sagas(constants, actions, remotes, selectors, entityUrl, additionalSaga) {

  const {
    LOAD_RECORD,
    LOAD_RECORDS,
    CREATE_RECORD,
    UPDATE_RECORD,
    UPDATE_RECORDS,
    SET_ACTIVE_RECORD,
    DELETE_RECORD,
    LOAD_RECORDS_META_DATA
  } = constants;

  const {
    loadRecordSuccess,
    loadRecordError,
    loadRecords: loadRecordsAction,
    loadRecordsSuccess,
    loadRecordsError,
    createRecordSuccess,
    createRecordError,
    updateRecordSuccess,
    updateRecordError,
    updateRecordsSuccess,
    updateRecordsError,
    setActiveRecordSuccess,
    setActiveRecordError,
    deleteRecordSuccess,
    deleteRecordError,
    loadRecordsCacheHit,
    loadRecordsMetaData: loadRecordsMetaDataAction,
    loadRecordsMetaDataSuccess,
    loadRecordsMetaDataError
  } = actions;

  const {
    loadRecords,
    loadRecordsMetaData,
    loadRecord,
    createRecord,
    updateRecord,
    updateRecords,
    setActiveRecord,
    deleteRecord
  } = remotes;

  const {
    selectRecords,
    selectUpdateTimestamp,
    selectParams
  } = selectors;


  const label = (
    (entityUrl === 'properties') && 'Property' ||
    (entityUrl === 'units') && 'Unit' ||
    (entityUrl === 'tenants') && 'Tenant' ||
    (entityUrl === 'bills') && 'Bill' ||
    (entityUrl === 'maintenance') && 'Category' ||
    (entityUrl === 'issues') && 'Issue'
  ) || entityUrl


  function* loadRecordsSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const load = yield race({
        explicitLoad: take(LOAD_RECORDS),
      });

      const { explicitLoad } = load;
      const { invalidateCache, params = {} } = explicitLoad || {};
      const lastLoad = yield select(selectUpdateTimestamp()); //Last API loaded Time
      const lastParams = yield select(selectParams()); //API Params
      const currentTimestamp = Math.floor(Date.now() / 1000);// Current Timestamp
      const VALID_CACHE_DIFF = -30; //milli seconds to wait for next API call

      yield put(loadRecordsMetaDataAction());// Calling Dropdown API's

      if (explicitLoad) {
        if (!invalidateCache && (lastLoad && (lastLoad - currentTimestamp) > VALID_CACHE_DIFF)) {
          yield put(loadRecordsCacheHit());
        } else {
          try {
            const result = yield call(loadRecords, entityUrl !== 'post-categories' && lastParams ? Object.assign({}, lastParams, params) : params);

            if(result && Array.isArray(result) && result.length > 0) {
              yield put(loadRecordsSuccess(result));
            } else if (result && typeof result === 'object' && Object.keys(result).length > 0) {
              const { response, total_items, current_page } = result;
              yield put(loadRecordsSuccess(response, total_items, current_page));
            } else {
              yield put(loadRecordsError());
            }
          } catch (error) {
            const Err = (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.message) ||
              (error &&
                error.response &&
                error.response.data &&
                error.response.data.error) ||
              "Fetch Records Failed.";
            yield put(loadRecordsError(Err && (typeof Err === 'string') ? Err : "Fetch Records Failed."));
          }
        }
      }
    }
  }

  function* loadRecordSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const loadRequest = yield race({
        request: take(LOAD_RECORD),
      });
      const { request } = loadRequest;

      if (request) {
        const { id } = request;
        try {
          const record = yield call(loadRecord, id);

          if (record) {
            // Delays the dispatch of loadRecordSuccess untill the store is populated with an initial list of records.
            while (true) { // eslint-disable-line no-constant-condition
              const recordsInStore = yield select(selectRecords());
              if (recordsInStore && recordsInStore.length > 0) {
                break;
              }
              yield delay(500);
            }
            yield put(loadRecordSuccess(record));
          } else {
            yield put(loadRecordError());
          }
        } catch (error) {
          const Err = (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
            "Fetch Record Failed.";
          yield put(loadRecordError(Err && (typeof Err === 'string') ? Err : "Fetch Record Failed."));
        }
      }
    }
  }

  function* createRecordSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const { create } = yield race({
        create: take(CREATE_RECORD)
      });
      const { record, setSubmitting, redirect, resetForm } = create || {};
      if (create) {
        yield call(setSubmitting, true);
        try {
          const result = yield call(createRecord, record);
          yield put(createRecordSuccess(result, `${label} Added.`));

          if (entityUrl === 'banner' || entityUrl === 'posts') {
            yield put(loadRecordsAction(true));
          }
          if (redirect)
            yield call(redirect)
        } catch (error) {
          const Err = (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
            "Create Record Failed.";
          yield put(createRecordError(Err && (typeof Err === 'string') ? Err : "Create Record Failed."));
        } finally {
          yield call(setSubmitting, false)
          if (resetForm)
            yield call(resetForm, {})
        }
      }
    }
  }

  function* editRecordSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const { edit } = yield race({
        edit: take(UPDATE_RECORD)
      });
      const { record, setSubmitting, redirect } = edit || {};
      if (edit) {
        yield call(setSubmitting, true);
        try {
          const result = yield call(updateRecord, record);
          //Removed the banner condition need to check
          // yield put(updateRecordSuccess(entityUrl === 'banner' ? record : result, `${label} Updated.`));
          yield put(updateRecordSuccess(result, `${label} Updated.`));
          yield call(redirect);
        } catch (error) {
          const Err = (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
            "Update Record Failed.";
          yield put(updateRecordError(Err && (typeof Err === 'string') ? Err : "Update Record Failed."));
        } finally {
          yield call(setSubmitting, false)
        }
      }
    }
  }

  function* editRecordsSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const { edit } = yield race({
        edit: take(UPDATE_RECORDS)
      });
      const { records, setSubmitting, redirect } = edit || {};
      if (edit) {
        yield call(setSubmitting, true);
        try {
          yield call(updateRecords, records);
          yield delay(1000);
          yield put(updateRecordsSuccess(records));

          if (redirect)
            yield call(redirect);
        } catch (error) {
          const Err = (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
            "Update Records Failed.";
          yield put(updateRecordsError(Err && (typeof Err === 'string') ? Err : "Update Records Failed."));
        } finally {
          yield call(setSubmitting, false)
        }
      }
    }
  }

  function* setActiveRecordSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const { set } = yield race({
        set: take(SET_ACTIVE_RECORD)
      });
      const { record, setSubmitting, redirect } = set || {};
      if (set) {
        yield call(setSubmitting, true);
        try {
          yield call(setActiveRecord, record);
          yield put(setActiveRecordSuccess(record, `${label} Updated.`));
          yield call(redirect);
        } catch (error) {
          const Err = (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
            "Set Active Status Failed.";
          yield put(setActiveRecordError(Err && (typeof Err === 'string') ? Err : "Set Active Status Failed."));
        } finally {
          yield call(setSubmitting, false)
        }
      }
    }
  }


  function* deleteRecordSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      const { del } = yield race({
        del: take(DELETE_RECORD)
      });
      const { id, setSubmitting, redirect } = del || {};
      if (del) {
        yield call(setSubmitting, true);
        try {
          yield call(deleteRecord, id);
          yield put(deleteRecordSuccess(id, `${label} Deleted.`));
          yield call(redirect);
        } catch (error) {
          const Err = (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.error) ||
            "Delete Record Failed.";
          yield put(deleteRecordError(Err && (typeof Err === 'string') ? Err : "Delete Record Failed."));
        } finally {
          yield call(setSubmitting, false)
        }
      }
    }
  }


  function* loadRecordsMetaDataSaga() {
    while (true) { // eslint-disable-line no-constant-condition
      yield race({
        metaData: take(LOAD_RECORDS_META_DATA),
      });

      try {
        let recordsMetaData = {};
        const user = yield select(selectUser()) || {};

        if (entityUrl === 'banner') {
          const vendors = yield call(loadRecordsMetaData, 'vendor/drop-down');
          recordsMetaData = {
            vendors: (
              vendors &&
              vendors.length > 0 &&
              vendors.map(a => Object.assign({}, a, { label: a.company_name, value: a.id }))),
            vendorsOptions: (
              vendors &&
              vendors.length > 0 &&
              vendors.filter(_ => (
                (
                  user &&
                  user.vendors &&
                  user.role === 'staff' &&
                  user.vendors.split(',').includes(_.id)
                ) ||
                !user.vendors ||
                user.role === 'admin' ||
                user.role === 'superAdmin'
              ) &&
                (
                  _.license_expiry_date && 
                  user.serverTime &&
                  moment(user.serverTime).diff(moment(_.license_expiry_date)) < 0) &&
                (
                  _.banner_subscription &&
                  _.banner_subscription.end_date &&
                  user.serverTime &&
                  moment(user.serverTime).diff(moment(_.banner_subscription.end_date)) < 0) && 
                  parseInt(_.banner_subscription.no_of_banner) > 0 &&
                  !_.is_inactive).map(a => Object.assign({}, a, { label: a.company_name, value: a.id }))),
          };
        } else if (entityUrl === 'accounts') {
          const vendors = yield call(loadRecordsMetaData, 'vendor/drop-down');
          recordsMetaData = {
            vendors: (
              vendors &&
              vendors.length > 0 &&
              ([{ label: 'Select All', value: 'All' }]).concat(vendors.map(a => Object.assign({}, a, { label: a.company_name, value: a.id }))))
          };
        } else if (entityUrl === 'posts') {
          const vendors = yield call(loadRecordsMetaData, 'vendor/drop-down');
          const postCategories = yield call(loadRecordsMetaData, 'post-categories/drop-down');
          const categories = postCategories && postCategories.length > 0 ? postCategories : []
          const postCategoriesOptions = _.orderBy(categories.map(c => Object.assign({}, c, { order: parseInt(c.order)})).filter(_ => !_.parent), 'order').map(c => {
            return Object.assign({}, c, {
              value: c.id,
              title: c.category_name,
              disabled: categories.filter(_ => _.parent === c.id).length > 0 ? true : false,
              children: _.orderBy(categories.filter(_ => _.parent === c.id), 'order').map(c2 => Object.assign({}, c2, {
                value: c2.id,
                title: c2.category_name,
                disabled: categories.filter(_ => _.parent === c2.id).length > 0 ? true : false,
                children: _.orderBy(categories.filter(_ => _.parent === c2.id), 'order').map(c3 => Object.assign({}, c3, {
                  value: c3.id,
                  title: c3.category_name,
                  disabled: categories.filter(_ => _.parent === c3.id).length > 0 ? true : false,
                  children: _.orderBy(categories.filter(_ => _.parent === c3.id), 'order').map(c4 => Object.assign({}, c4, {
                    value: c4.id,
                    title: c4.category_name,
                    children: _.orderBy(categories.filter(_ => _.parent === c4.id), 'order')
                  }))
                }))
              }))
            })
          });

          recordsMetaData = {
            vendors: (
              vendors &&
              vendors.length > 0 &&
              vendors.map(a => Object.assign({}, a, { label: a.company_name, value: a.id }))),
            vendorsOptions: (
              vendors &&
              vendors.length > 0 &&
              vendors.filter(_ => (
                (
                  user &&
                  user.vendors &&
                  user.role === 'staff' &&
                  user.vendors.split(',').includes(_.id)
                ) ||
                !user.vendors ||
                user.role === 'admin' ||
                user.role === 'superAdmin'
              ) &&
                (
                  _.license_expiry_date &&
                  user.serverTime && 
                  moment(user.serverTime).diff(moment(_.license_expiry_date)) < 0) &&
                (
                  _.post_subscription &&
                  _.post_subscription.end_date &&
                  user. serverTime &&
                  moment(user.serverTime).diff(moment(_.post_subscription.end_date)) < 0) && 
                  parseInt(_.post_subscription.no_of_post) > 0 &&
                  !_.is_inactive).map(a => Object.assign({}, a, { label: a.company_name, value: a.id }))),
            postCategories: (
              postCategories &&
              postCategories.length > 0 &&
              postCategories.map(a => Object.assign({}, a, { label: a.category_name, value: a.id }))),
            postCategoriesOptions
          };
        }

        yield put(loadRecordsMetaDataSuccess(recordsMetaData));
      } catch (error) {
        const Err = (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.error) ||
          "Fetch Dropdown Records Failed.";
        yield put(loadRecordsMetaDataError(Err && (typeof Err === 'string') ? Err : "Fetch Dropdown Records Failed."));
      }
    }
  }

  function* recordsSaga() {
    yield all([
      loadRecordSaga(),
      loadRecordsSaga(),
      createRecordSaga(),
      editRecordSaga(),
      editRecordsSaga(),
      setActiveRecordSaga(),
      deleteRecordSaga(),
      loadRecordsMetaDataSaga()
    ])
  }

  return function* rootSaga() {
    yield all([
      recordsSaga(),
      additionalSaga()
    ])
  }
}
