/***
 * 
 * 
 * Network Error Component
 * 
 * 
 */

import React from "react";
import { Col, Row } from "reactstrap"


function NetworkError() {

    return (
        <>
            <Row style={{ minHeight: "500px", height: "100vh", display: "flex", alignItems: "center", textAlign: "center", padding: "25px" }}>
                <Col item lg={12}>
                    <h1>Whoops!</h1>
                    <p>No Internet connection found. Check your connection and try again.</p>
                </Col>
            </Row>
        </>
    )
}

export default NetworkError;
