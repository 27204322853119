import { combineReducers } from "redux"

import session from './session/reducer'

//Schema
import schema from "../schema"

const asyncReducers = (schema || []).filter(_ => _.visible).reduce((a, el) => el.reducer ? Object.assign({}, a, { [el.name]: el.reducer }) : a, {})


export const rootReducer = () => {
  const appReducer = combineReducers({ session, ...asyncReducers });
  return (state, action) => {
    if (action.type === 'lg/session/LOG_OUT') {
      state = undefined;
    }
    return appReducer(state, action);
  }
};

export default rootReducer
