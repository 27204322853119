import React, { Suspense, useEffect, useState } from "react"
import PropTypes from 'prop-types';
import store2 from 'store2';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux'
import { selectUser, selectLoggedIn, selectLoading } from "store/session/selectors"
import { verifySession } from 'store/session/actions';

//not found page
import NotFoundPage from "pages/NotFound";

//redirect page
import Redirect from "pages/Redirect";

//Router
import withRouter from "helpers/withRouter";

//Network
import NetworkError from "components/NetworkError";

//Error Boundary
import ErrorBoundary from "components/ErrorBoundary";

// layouts
import DefaultLayout from "components/DefaultLayout"
import NonAuthLayout from "components/BlankLayout"

import SquareLoader from 'react-spinners/SquareLoader'
import { NavigationType, useNavigationType } from "react-router-dom";

const AppLoader = () => (<div className="route_loader"><SquareLoader color="#50CC89" /></div>);

const useBackButton = () => {
  const navType = useNavigationType();
  return navType === NavigationType.Pop;
};

const AppRoute = ({
  component: Component,
  ...rest
}) => {
  const { name, path, loggedIn, user = {}, loading, params = {}, location, dispatch } = rest || {};
  const { access, role } = user;
  const { type } = params;
  const { pathname } = location;
  const [prevLocation, setPrevLocation] = useState(false);
  const [online, setOnline] = useState(window.navigator.onLine);

  //Below useEffect for calling session API on each router change.
  useEffect(() => {
      if(prevLocation !== pathname && loggedIn && !["/", "/logout"].includes(path)){
        setPrevLocation(pathname);
        dispatch(verifySession(store2.get('secret'), store2.get('accType')));
      }
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("online", () => setOnline(true));
    window.addEventListener("offline", () => setOnline(false));
    return () => {
      window.addEventListener("online", () => setOnline(true));
      window.addEventListener("offline", () => setOnline(false));
    }
  }, []);

  const scrollToTop = () => window.scrollTo(0, 0);
  const isPop = useBackButton();

  useEffect(() => {
      scrollToTop();
  }, [location, isPop])

  /**
   * Without login route url redirecting to vendor login
   */
  if(!loggedIn && !loading && path === "/"){
    return <Redirect {...rest} redirectPath="/vendor/login" />
  }
  
  /**
   * Suppose if the user trying the auth route after loggedin. It will redirect to route url
   */
  if (loggedIn && !loading && !["/", "/logout"].includes(path) && ['login', 'forgot-password', 'reset-password', 'verify'].includes(name)) {
    return <Redirect {...rest} />
  }


  /**
   * Below condition to block the not access page
   */
  if ((!loggedIn && !loading && !['login', 'forgot-password', 'reset-password', 'verify', 'logout'].includes(name)) || (loggedIn && !loading && !["/", "/logout"].includes(path) && access && !access.split(",").map(r => r).includes(name))) {
    return <NotFoundPage />
  }
  
  const Layout = loggedIn ? DefaultLayout : NonAuthLayout;
  const vtheme = (type === 'vendor' || role === 'vendor') ? true : false;

  if(!online)
    return <NetworkError />

  if(loading){
    return <AppLoader />;
  }
  return (
    <Suspense fallback={<AppLoader />}>
      <ErrorBoundary>
        <Layout vtheme={vtheme}>
          <Component {...rest} />
        </Layout>
      </ErrorBoundary>
    </Suspense>
  )
}

AppRoute.propTypes = {
  component: PropTypes.any,
  location: PropTypes.object,
  user: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  user: selectUser(),
  loggedIn: selectLoggedIn(),
  loading: selectLoading()
});


export default withRouter(connect(mapStateToProps)(AppRoute))
