import PropTypes from "prop-types";
import React, { Component } from "react";

// MetisMenu
import { Link } from "react-router-dom";
import { createStructuredSelector } from 'reselect';
import { connect } from "react-redux";

//i18n
import { withTranslation } from "react-i18next";

//schema
import schema from "schema";

import { selectUser } from '../../store/session/selectors';
import withRouter from "helpers/withRouter";
class SidebarContent extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { user = {}, location = {}, navigate } = this.props;
    const { access, role } = user;
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className={location && location.pathname === "/" ? "active" : null} onClick={() => {
              navigate(`/`);
              var body = document.body;
              if (window.screen.width <= 998) {
                body.classList.toggle("sidebar-enable");
              }
            }}>
              <Link to={"/"} aria-label="dashboard">
                <i class="fi fi-rs-chart-line-up"></i>
                <span>{this.props.t("Dashboard")}</span>
              </Link>
              <i className="fa fa-fw fa-angle-right" style={{ fontSize: '22px' }}></i>
            </li>
            {(schema || []).filter(_ => ((access && access.split(",").includes(_.name)) || (role && role === 'superAdmin')) && _.visible && !_.hideMenu).map((s) => (
              <li key={s.schemaId} className={location && location.pathname.indexOf(s.path) > -1 ? "active" : null} onClick={() => {
                var body = document.body;
                if (window.screen.width <= 998) {
                  body.classList.toggle("sidebar-enable");
                }
              }}>
                <Link to={`${s.path}`} aria-label="title">
                  <i className={`fi ${s.icon}`} />
                  <span>{this.props.t(`${s.title}`)}</span>
                </Link>
                <i className="fa fa-fw fa-angle-right" style={{ fontSize: '22px' }}></i>
              </li>
            ))}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  type: PropTypes.string,
  user: PropTypes.object,
  navigate: PropTypes.func
};

const mapStateToProps = createStructuredSelector({
  user: selectUser()
});

function mapDispatchToProps(dispatch) {
  return {
    dispatch
  };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SidebarContent)));
