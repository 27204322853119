/**
 * 
 * 
 * View Location
 * 
 * 
 */


import React from "react";
import { useLoadScript, GoogleMap, Marker } from "@react-google-maps/api";
import { map } from "config";
const placesLibrary = ["places"];

function viewLocation(props) {
    const { value } = props;
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyDt1WML79vgd-irSGt1EO__9QtFyPqQPGo",
        libraries: placesLibrary
    });

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    const locationValue = value && value.split(",");

    return (
        <React.Fragment>
            {locationValue ? <div className="pt-3">
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%',
                        height: '200px'
                    }}
                    center={{
                        lat: parseFloat(locationValue[0]),
                        lng: parseFloat(locationValue[1])
                    }}
                    options={{ disableDefaultUI: true }}
                    zoom={13} >
                    <Marker position={{
                        lat: parseFloat(locationValue[0]),
                        lng: parseFloat(locationValue[1])
                    }} />
                </GoogleMap>
            </div> : null}
        </React.Fragment>
    );
}

export default viewLocation;
