
/*
 *
 *  utils
 *
 */
import moment from "moment";

/**
 * 
 * @param {array} records 
 * @returns 
 */
function mapAccounts(records) {
    return records.filter(_ => _.role !== 'superAdmin').map(r => Object.assign({}, r, { phone: r.phone && r.country_code ? `${r.country_code?.replace('+', '')}${r.phone}` : ''}));
}

/**
 * 
 * @param {array} records 
 * @returns 
 */
function mapBanner(records) {
    return records.map(r => Object.assign({}, r, { lat_long: `${r.latitude},${r.longitude}`, global_display: r.global_display ? "Y" : "N" }))
}

/**
 * 
 * @param {array} records 
 * @returns 
 */
function mapVendor(records) {
    return records.map(r => Object.assign({}, r, { lat_long: `${r.latitude},${r.longitude}`, license_expiry_date: moment(r.license_expiry_date).subtract(1, "days"), company_phone_no: r.company_phone_no && r.country_code ? `${r.country_code?.replace('+', '')}${r.company_phone_no}` : '' }))
}

/**
 * 
 * @param {array} records 
 * @returns 
 */
function mapPosts(records) {
    return records.map(r => Object.assign({}, r, { lat_long: `${r.latitude},${r.longitude}`, phone_no: r.phone_no && r.country_code ? `${r.country_code?.replace('+', '')}${r.phone_no}` : '', global_display: r.global_display ? "Y" : "N", is_free_delivery: r.is_free_delivery ? "Y" : "N", free_world_wide_shipping: r.free_world_wide_shipping ? "Y" : "N", secure: r.secure ? "Y" : "N" }))
}

/**
 * 
 * @param {array} records 
 * @returns 
 */
function mapComments(records) {
    return records.map((r, i) => Object.assign({}, r, { rowId: i + 1 }))
}


/**
 * 
 * @param {array} records 
 * @returns 
 */
function mapUsers(records) {
    return records.map(r => Object.assign({}, r, { phone: r.phone && r.country_code ? `${r.country_code?.replace('+', '')}${r.phone}` : '' }))
}


/**
 * @param {object} records 
 * @param {string} name 
 */
export default function mapRecords(records, name) {

    switch (name) {
        case 'accounts':
            return mapAccounts(records);
        case 'banner':
            return mapBanner(records);
        case 'vendor':
            return mapVendor(records);
        case 'posts':
            return mapPosts(records);
        case 'comments':
            return mapComments(records);
        case 'user':
            return mapUsers(records);
        default:
            return records;
    }
}
