/*
 *
 *  constants
 *
 */

export default function constants(name) {
  const url = `lg/${name}`;

  return {
    LOAD_RECORDS: `${url}/LOAD_RECORDS`,
    LOAD_RECORDS_SUCCESS: `${url}/LOAD_RECORDS_SUCCESS`,
    LOAD_RECORDS_ERROR: `${url}/LOAD_RECORDS_ERROR`,
    LOAD_RECORD: `${url}/LOAD_RECORD`,
    LOAD_RECORD_SUCCESS: `${url}/LOAD_RECORD_SUCCESS`,
    LOAD_RECORD_ERROR: `${url}/LOAD_RECORD_ERROR`,
    CREATE_RECORD: `${url}/CREATE_RECORD`,
    CREATE_RECORD_SUCCESS: `${url}/CREATE_RECORD_SUCCESS`,
    CREATE_RECORD_ERROR: `${url}/CREATE_RECORD_ERROR`,
    UPDATE_RECORD: `${url}/UPDATE_RECORD`,
    UPDATE_RECORD_SUCCESS: `${url}/UPDATE_RECORD_SUCCESS`,
    UPDATE_RECORD_ERROR: `${url}/UPDATE_RECORD_ERROR`,
    UPDATE_RECORDS: `${url}/UPDATE_RECORDS`,
    UPDATE_RECORDS_SUCCESS: `${url}/UPDATE_RECORDS_SUCCESS`,
    UPDATE_RECORDS_ERROR: `${url}/UPDATE_RECORDS_ERROR`,
    SET_ACTIVE_RECORD: `${url}/SET_ACTIVE_RECORD`,
    SET_ACTIVE_RECORD_SUCCESS: `${url}/SET_ACTIVE_RECORD_SUCCESS`,
    SET_ACTIVE_RECORD_ERROR: `${url}/SET_ACTIVE_RECORD_ERROR`,
    DELETE_RECORD: `${url}/DELETE_RECORD`,
    DELETE_RECORD_SUCCESS: `${url}/DELETE_RECORD_SUCCESS`,
    DELETE_RECORD_ERROR: `${url}/DELETE_RECORD_ERROR`,
    LOAD_RECORDS_VALID_CACHE: `${url}/LOAD_RECORDS_VALID_CACHE`,
    LOAD_RECORDS_META_DATA: `${url}/LOAD_RECORDS_META_DATA`,
    LOAD_RECORDS_META_DATA_SUCCESS: `${url}/LOAD_RECORDS_META_DATA_SUCCESS`,
    LOAD_RECORDS_META_DATA_ERROR: `${url}/LOAD_RECORDS_META_DATA_ERROR`,
    UPDATE_PARAMS: `${url}/UPDATE_PARAMS`
  };
}
