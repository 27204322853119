// global variables
import './config/config';

import React from "react"
import ReactDOM from 'react-dom/client';
import * as serviceWorker from "./serviceWorker"
import "./i18n"

import { Provider } from "react-redux"
import store from "./store"

import { RouterProvider, createBrowserRouter } from "react-router-dom"

// Import Routes
import routes from "./routes/"
import AppRoute from "./routes/route"

// Import scss
import "./assets/scss/theme.scss"
import 'react-toastify/dist/ReactToastify.css'

//Creating a router
const router = createBrowserRouter(routes.map((route, idx) => (
  {
    path: route.path,
    element: <AppRoute key={idx} path={route.path} component={route.component} {...route} />,
    children: route.children && route.children.length > 0 && route.children.map(({ component: Component, path }) => ({
      path,
      element: <Component path={route.path} {...route} />
    }))
  }
)));

//Root Element
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>
);

serviceWorker.unregister()