import React, { Component } from "react"
import PropTypes from 'prop-types'
import { connect } from "react-redux"

// Layout Related Components
import Header from "./Header"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

class Layout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      width: 0,
      height: 0,
      showMenu: false
    }
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  componentDidMount() {
    if (this.props.vtheme){
      document.body.setAttribute("data-theme", "vendor")
    } else {
      document.body.removeAttribute("data-theme")
    }
    
    document.body.setAttribute("data-sidebar", "dark")

    // Scroll Top to 0
    window.scrollTo(0, 0)
    // let currentage = this.capitalizeFirstLetter(this.props.location.pathname)
  }

  toggleMenuCallback = () => {
    this.setState({ showMenu: !this.state.showMenu })
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  render() {
    const { user = {}, onReload } = this.props;

    return (
      <React.Fragment>
        <div id="layout-wrapper">
          <Header
            toggleMenuCallback={this.toggleMenuCallback}
            user={user}
            onReload={onReload}
          />
          <Sidebar showMenu={this.state.showMenu} />
          <div className="main-content">{this.props.children}</div>
          <Footer />
        </div>
      </React.Fragment>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.object,
  dispatch: PropTypes.func,
  user: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  vtheme: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    ...state.Layout,
    user: state && state.session && state.session.user
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Layout)
