/*
 *
 *  actions
 *
 */

export default function actions(constants) {
  const {
    LOAD_RECORDS,
    LOAD_RECORDS_SUCCESS,
    LOAD_RECORDS_ERROR,
    LOAD_RECORD,
    LOAD_RECORD_SUCCESS,
    LOAD_RECORD_ERROR,
    CREATE_RECORD,
    CREATE_RECORD_SUCCESS,
    CREATE_RECORD_ERROR,
    UPDATE_RECORD,
    UPDATE_RECORD_SUCCESS,
    UPDATE_RECORD_ERROR,
    UPDATE_RECORDS,
    UPDATE_RECORDS_SUCCESS,
    UPDATE_RECORDS_ERROR,
    SET_ACTIVE_RECORD,
    SET_ACTIVE_RECORD_SUCCESS,
    SET_ACTIVE_RECORD_ERROR,
    DELETE_RECORD,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_ERROR,
    LOAD_RECORDS_VALID_CACHE,
    LOAD_RECORDS_META_DATA,
    LOAD_RECORDS_META_DATA_SUCCESS,
    LOAD_RECORDS_META_DATA_ERROR,
    UPDATE_PARAMS
  } = constants;


  function loadRecordsCacheHit() {
    return {
      type: LOAD_RECORDS_VALID_CACHE
    };
  }

  /**
   * @param {boolean} invalidateCache 
   */
  function loadRecords(invalidateCache, params, disableLoading) {
    return {
      type: LOAD_RECORDS,
      invalidateCache,
      params,
      disableLoading
    };
  }

  /**
   * @param {array} records 
   */
  function loadRecordsSuccess(records, total_items, current_page) {
    return {
      type: LOAD_RECORDS_SUCCESS,
      records,
      total_items,
      current_page
    };
  }

  /**
   * @param {string} error 
   */
  function loadRecordsError(error) {
    return {
      type: LOAD_RECORDS_ERROR,
      error
    };
  }

  /**
   * @param {integer} id 
   */
  function loadRecord(id) {
    return {
      type: LOAD_RECORD,
      id
    };
  }

  /**
   * @param {object} record 
   */
  function loadRecordSuccess(record) {
    return {
      type: LOAD_RECORD_SUCCESS,
      record
    };
  }

  /**
   * @param {string} error 
   */
  function loadRecordError(error) {
    return {
      type: LOAD_RECORD_ERROR,
      error
    };
  }

  /**
   * 
   * @param {object} record 
   * @param {function} setSubmitting 
   * @param {function} redirect 
   * @param {function} resetForm 
   * @returns 
   */
  function createRecord(record, setSubmitting, redirect, resetForm) {
    return {
      type: CREATE_RECORD,
      record,
      setSubmitting,
      redirect,
      resetForm
    };
  }

  /**
   * 
   * @param {object} record 
   * @param {string} success 
   * @returns 
   */
  function createRecordSuccess(record, success) {
    return {
      type: CREATE_RECORD_SUCCESS,
      record,
      success
    };
  }

  /**
   * @param {string} error 
   */
  function createRecordError(error) {
    return {
      type: CREATE_RECORD_ERROR,
      error
    };
  }

  /**
   * 
   * @param {object} record 
   * @param {function} setSubmitting 
   * @param {function} redirect 
   * @returns 
   */
  function updateRecord(record, setSubmitting, redirect) {
    return {
      type: UPDATE_RECORD,
      record,
      setSubmitting,
      redirect
    };
  }

  /**
   * 
   * @param {object} record 
   * @param {string} success 
   * @returns 
   */
  function updateRecordSuccess(record, success) {
    return {
      type: UPDATE_RECORD_SUCCESS,
      record,
      success
    };
  }

  /**
   * @param {string} error 
   */
  function updateRecordError(error) {
    return {
      type: UPDATE_RECORD_ERROR,
      error
    };
  }


  /**
 * 
 * @param {object} record 
 * @param {function} setSubmitting 
 * @param {function} redirect 
 * @returns 
 */
  function updateRecords(records, setSubmitting, redirect) {
    return {
      type: UPDATE_RECORDS,
      records,
      setSubmitting,
      redirect
    };
  }

  /**
   * 
   * @param {object} records
   * @param {string} success 
   * @returns 
   */
  function updateRecordsSuccess(records, success) {
    return {
      type: UPDATE_RECORDS_SUCCESS,
      records,
      success
    };
  }

  /**
   * @param {string} error 
   */
  function updateRecordsError(error) {
    return {
      type: UPDATE_RECORDS_ERROR,
      error
    };
  }

  /**
   * 
   * @param {object} record 
   * @param {function} setSubmitting 
   * @param {function} redirect 
   * @returns 
   */
  function setActiveRecord(record, setSubmitting, redirect) {
    return {
      type: SET_ACTIVE_RECORD,
      record,
      setSubmitting,
      redirect
    };
  }

  /**
   * 
   * @param {object} record 
   * @param {string} success 
   * @returns 
   */
  function setActiveRecordSuccess(record, success) {
    return {
      type: SET_ACTIVE_RECORD_SUCCESS,
      record,
      success
    };
  }

  /**
   * @param {string} error 
   */
  function setActiveRecordError(error) {
    return {
      type: SET_ACTIVE_RECORD_ERROR,
      error
    };
  }

  /**
   * 
   * @param {integer} id 
   * @param {function} setSubmitting 
   * @param {function} redirect 
   * @returns 
   */
  function deleteRecord(id, setSubmitting, redirect) {
    return {
      type: DELETE_RECORD,
      id,
      setSubmitting,
      redirect
    };
  }

  /**
   * 
   * @param {integer} id 
   * @param {string} success 
   * @returns 
   */
  function deleteRecordSuccess(id, success) {
    return {
      type: DELETE_RECORD_SUCCESS,
      id,
      success
    };
  }

  /**
   * @param {string} error 
   */
  function deleteRecordError(error) {
    return {
      type: DELETE_RECORD_ERROR,
      error
    };
  }

  function loadRecordsMetaData() {
    return {
      type: LOAD_RECORDS_META_DATA
    }
  }

  /**
   * 
   * @param {object} recordsMetaData 
   * @returns 
   */
  function loadRecordsMetaDataSuccess(recordsMetaData) {
    return {
      type: LOAD_RECORDS_META_DATA_SUCCESS,
      recordsMetaData
    }
  }

  /**
   * 
   * @param {string} error 
   * @returns 
   */
  function loadRecordsMetaDataError(error) {
    return {
      type: LOAD_RECORDS_META_DATA_ERROR,
      error
    }
  }

  /**
   * 
   * @param {object} params 
   * @returns 
   */
  function updateParams(params) {
    return {
      type: UPDATE_PARAMS,
      params
    };
  }

  return {
    loadRecord,
    loadRecordSuccess,
    loadRecordError,
    loadRecords,
    loadRecordsSuccess,
    loadRecordsError,
    createRecord,
    createRecordSuccess,
    createRecordError,
    updateRecord,
    updateRecordSuccess,
    updateRecordError,
    updateRecords,
    updateRecordsSuccess,
    updateRecordsError,
    setActiveRecord,
    setActiveRecordSuccess,
    setActiveRecordError,
    deleteRecord,
    deleteRecordSuccess,
    deleteRecordError,
    loadRecordsCacheHit,
    loadRecordsMetaData,
    loadRecordsMetaDataSuccess,
    loadRecordsMetaDataError,
    updateParams
  };
}
