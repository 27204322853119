/*
 *
 *   reducer
 *
 */
import { produce } from 'immer';


const initialState = {
  records: [],
  record: {},
  recordsMetaData: {},
  params: { limit: 10, offset: 0 },
  loading: false,
  error: false,
  success: false,
  lastUpdate: null,
  total_items: false,
  current_page: 1
};

/**
 * @param {object} constants 
 * @param {string} name 
 */
export default function reducer(constants) {

  const {
    LOAD_RECORD,
    LOAD_RECORD_SUCCESS,
    LOAD_RECORD_ERROR,
    LOAD_RECORDS,
    LOAD_RECORDS_SUCCESS,
    LOAD_RECORDS_ERROR,
    CREATE_RECORD_SUCCESS,
    CREATE_RECORD_ERROR,
    UPDATE_RECORD_SUCCESS,
    UPDATE_RECORD_ERROR,
    UPDATE_RECORDS_SUCCESS,
    UPDATE_RECORDS_ERROR,
    SET_ACTIVE_RECORD_SUCCESS,
    SET_ACTIVE_RECORD_ERROR,
    DELETE_RECORD_SUCCESS,
    DELETE_RECORD_ERROR,
    LOAD_RECORDS_VALID_CACHE,
    LOAD_RECORDS_META_DATA_SUCCESS,
    LOAD_RECORDS_META_DATA_ERROR,
    UPDATE_PARAMS
  } = constants;

  return function recordsReducer(state = initialState, { type, id, record, records, recordsMetaData = {}, error, success, key, totalPageCount, disableLoading, params, total_items, current_page }) {
    
    return produce(state, draft => {
      switch (type) {
        case LOAD_RECORDS_VALID_CACHE:
          draft.loading = false
          draft.error = false
          draft.success = false
          break
        case LOAD_RECORDS:
          draft.loading = disableLoading ? false : true
          draft.error = false
          draft.success = false
          draft.params = draft.params && params ? Object.assign({}, draft.params, params) : params || draft.params
          break
        case LOAD_RECORD:
          draft.loading = true
          draft.error = false
          draft.success = false
          break
        case LOAD_RECORDS_SUCCESS:
          draft.records = records
          draft.total_items = total_items
          draft.current_page = current_page || draft.current_page
          draft.lastUpdate = Math.floor(Date.now() / 1000)
          draft.loading = false
          draft.error = false
          draft.success = false
          break
        case LOAD_RECORD_SUCCESS:
          draft.record = record
          draft.lastUpdate = Math.floor(Date.now() / 1000)
          draft.loading = false
          draft.error = false
          draft.success = false
          break
        case LOAD_RECORDS_META_DATA_SUCCESS:
          draft.recordsMetaData = Object.assign({}, state.recordsMetaData, recordsMetaData)
          draft.error = false
          draft.success = false
          break
        case CREATE_RECORD_SUCCESS:
          draft.records = [record].concat(state.records)
          draft.loading = false
          draft.error = false
          draft.success = success
          break
        case UPDATE_RECORD_SUCCESS:
        case SET_ACTIVE_RECORD_SUCCESS:
          draft.records = state.records.find(r => record.id === r.id) ? state.records.map((r) => record.id === r.id ? Object.assign({}, r, record) : r) : state.records.concat([{ ...record }])
          draft.loading = false
          draft.error = false
          draft.success = type === SET_ACTIVE_RECORD_SUCCESS ? success : false
          break
        case UPDATE_RECORDS_SUCCESS:
          draft.loading = false
          draft.error = false
          draft.success = success
          break
        case DELETE_RECORD_SUCCESS:
          draft.records = state.records.filter((r) => r.id.toString() !== id.toString())
          draft.loading = false
          draft.error = false
          draft.success = success
          break
        case LOAD_RECORDS_ERROR:
        case LOAD_RECORD_ERROR:
        case CREATE_RECORD_ERROR:
        case UPDATE_RECORD_ERROR:
        case UPDATE_RECORDS_ERROR:
        case SET_ACTIVE_RECORD_ERROR:  
        case DELETE_RECORD_ERROR:
          draft.loading = false
          draft.error = error
          draft.success = false
          break
        case LOAD_RECORDS_META_DATA_ERROR:
          draft.error = error
          draft.success = false
          break
        case UPDATE_PARAMS:
          draft.params = draft.params && params ? Object.assign({}, draft.params, params) : params || draft.params
          draft.loading = false
          draft.error = false
          draft.success = false
      }
    });

  };
}
