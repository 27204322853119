module.exports = {
  map: {
    API_KEY: "AIzaSyDt1WML79vgd-irSGt1EO__9QtFyPqQPGo"
  },
  google: {
    API_KEY: "",
    CLIENT_ID:"",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
}
