/*
 *
 * Session constants
 *
 */

export const VERIFY_SESSION = 'lg/session/VERIFY_SESSION';
export const VERIFY_SESSION_SUCCESS = 'lg/session/VERIFY_SESSION_SUCCESS';
export const VERIFY_SESSION_ERROR = 'lg/session/VERIFY_SESSION_ERROR';

export const LOG_IN = 'lg/session/LOG_IN';
export const LOG_IN_SUCCESS = 'lg/session/LOG_IN_SUCCESS';
export const LOG_IN_ERROR = 'lg/session/LOG_IN_ERROR';

export const LOG_OUT = 'lg/session/LOG_OUT';
export const LOG_OUT_SUCCESS = 'lg/session/LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'lg/session/LOG_OUT_ERROR';

export const FORGOT_PASSWORD = 'lg/session/FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'lg/session/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'lg/session/FORGOT_PASSWORD_ERROR';

export const CHANGE_PASSWORD = 'lg/session/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'lg/session/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_ERROR = 'lg/session/CHANGE_PASSWORD_ERROR';

export const VERIFY_OTP = 'lg/session/VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'lg/session/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'lg/session/VERIFY_OTP_ERROR';

export const NOTIFICATION = 'lg/session/NOTIFICATION';
export const NOTIFICATION_SUCCESS = 'lg/session/NOTIFICATION_SUCCESS';
export const NOTIFICATION_ERROR = 'lg/session/NOTIFICATION_ERROR';

export const ROUTER = '@@router/LOCATION_CHANGE';
export const CLEAR_CACHE = '@@router/CLEAR_CACHE';

export const UPLOAD_FILES = '@@router/UPLOAD_FILES';