/**
 * 
 * 
 * Gallery
 * 
 */

import React from "react"
import FsLightbox from "fslightbox-react"
class Gallery extends React.Component {
    constructor(props){
        super(props)
        this.state = { toggler: false }
    }

    render() {
        const { children, items = [] } = this.props
        const { toggler } = this.state

        return (
            <React.Fragment>
                {children && typeof children === 'function' ? children(() => this.setState({ toggler: !toggler })) : null}
                <FsLightbox
                    toggler={toggler}
                    sources={items || []}
                />
            </React.Fragment>
        ) 
    }
}

export default Gallery