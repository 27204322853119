/**
 * 
 * Notification Modal
 * 
 */



import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from "reactstrap"
import { connect } from 'react-redux';
import Gallery from 'components/Gallery';

import { selectUser } from 'store/session/selectors';

class NotificationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = { showModal: false }
    }


    render() {

        const { user = {} } = this.props;
        const { notification_details = [] } = user;

        return (
            <React.Fragment>
                {notification_details && notification_details.length > 0 ?<div className="btn header-item noti-icon d-flex align-items-center" onClick={() => this.setState({ showModal: true })}>
                    <i className="bx bx-bell bx-tada" />
                    <span className="badge bg-danger rounded-pill">{notification_details && notification_details.length || '0'}</span>
                </div> : null}
                <Modal
                    isOpen={this.state.showModal}
                    size='lg'
                >
                    <div className="modal-header simple-modal-header">
                        <h5 className="modal-title mt-0">
                            Notifications
                        </h5>
                        <button
                            type="button"
                            onClick={() => this.setState({ showModal: false })}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true">&times</span>
                        </button>
                    </div>
                    <div className="modal-body simple-modal-body">
                        {notification_details && notification_details.length > 0 ?
                            (notification_details || []).map((data, index) => {
                                return (
                                    <div key={index} className='d-flex flex-wrap flex-column'>
                                        <div className='d-flex w-100 h-100 mb-3'> 
                                            <Gallery items={[<img src={data.public_url} alt="notification" />]}>
                                                {(toggle) => <img src={data.public_url} alt="notification" width="100%" onClick={toggle} />}
                                            </Gallery> <br /> 
                                        </div>
                                    </div>
                                )
                            }) : <div className="d-flex justify-content-center">No Notifications to display.</div>}
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

NotificationModal.propTypes = {
    t: PropTypes.any,
    notification: PropTypes.any,
    user: PropTypes.any
}



const mapStateToProps = (state = {}) => {
    return {
        user: selectUser()(state)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationModal)