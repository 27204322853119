import { all, fork } from "redux-saga/effects"

import sessionSaga from './session/sagas'


//Schema
import schema from "../schema/"

const additionalSagas  = (schema || []).filter(_ => _.visible).map(_ => fork(_.saga))


export default function* rootSaga() {
  yield all([
    fork(sessionSaga)
  ].concat(additionalSagas))
}
