/*
 *
 *  session remotes
 *
 */

import store2 from 'store2';
import api from '../../helpers/api';
import { checkIsBefore } from 'helpers/tools';
import moment from 'moment';

export async function verifySession(type) {
  return await api
    .get(`/rest/${type}/session`)
    .then(response => {
      if (!response.data || (response.data && response.data.error)) {
        return Promise.reject('Session Timout');
      }
      const role = response && response.data.role;
      store2.set('accType', ['superAdmin', 'admin', 'staff'].includes(role) ? 'accounts' : role);

      if (role === 'vendor'){
        return Object.assign({}, response.data, { access: "post-categories,banner,posts,settings,comments", company_phone_no: response.data?.company_phone_no && response.data?.country_code ? `${response.data.country_code?.replace('+', '')}${response.data.company_phone_no}` : '', lat_long: `${response.data.latitude},${response.data.longitude}`, license_expiry_date: moment(response.data.license_expiry_date).subtract(1, "days"), license_expired_in_10_days: checkIsBefore(response.data.license_expiry_date, moment(response.data.serverTime).utcOffset(240).add(10, 'days')), license_document: ''  })
      }
      return Object.assign({}, response.data, { phone: response.data?.phone && response.data?.country_code ? `${response.data.country_code?.replace('+', '')}${response.data.phone}` : '' });
    })
    .catch(error => Promise.reject(error));
}

/**
 * 
 * @param {object} record 
 * @returns 
 */
export async function logIn(record) {
  return await api
    .post(`/${record.type}/login`, record)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
}

export async function logOut() {
  return await api
    .delete(`/session`)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
}

/**
 * @param {object} record 
 */
export async function forgotPassword(record) {
  return await api
    .post(`/rest/${record.type}/forget-password`, record)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
}

/**
 * @param {object} record 
 */
export async function changePassword(record) {
  return await api
    .post(`/rest/${record.type}/reset-password`, record)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
}


/**
 * 
 * @param {object} record 
 * @returns 
 */
export async function verifyOtp(record) {
  return await api.post(`/${record.type}/verification`, record).then(response => { return response.data }).catch(error => Promise.reject(error));
}


export async function notification() {
  return await api.get('/rest/notification').then(response => { return response.data }).catch(error => Promise.reject(error));
}


export default {
  verifySession,  
  logIn,
  logOut,
  forgotPassword,
  changePassword,
  verifyOtp,
  notification
};
