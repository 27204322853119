import React, { Component } from "react"
import PropTypes from 'prop-types'

class NonAuthLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  componentDidMount() {
    if (this.props.vtheme) {
      document.body.setAttribute("data-theme", "vendor")
    } else {
      document.body.removeAttribute("data-theme")
    }
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }
  
  render() {
    return <React.Fragment>{this.props.children}</React.Fragment>
  }
}

NonAuthLayout.propTypes = {
  children: PropTypes.object,
  vtheme: PropTypes.bool
}

export default NonAuthLayout
