import { useLocation, useParams, useNavigate } from 'react-router-dom';

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const location = useLocation();
    const params = useParams();
    let navigate = useNavigate();
    
    return (
      <Component
        location={location}
        params={params}
        navigate={navigate}
        {...props}
        />
    );
  };
  
  return Wrapper;
};

export default withRouter;