import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row } from "reactstrap"

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state to indicate error
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error('Error caught by ErrorBoundary:', error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can customize the error message here
            return <Row style={{ minHeight: "500px", height: "100vh", display: "flex", alignItems: "center", textAlign: 'center', padding: "25px" }}>
                <Col item lg={12}>
                    <h1>Whoops!</h1>
                    <p>Thank you for visiting <Link to="/" aria-label="home link">Dealpopup.com</Link>, we're currently experiencing some technical difficulties. Rest assured, we are doing everything we can to rectify the problem. Please check back soon.</p>
                </Col>
            </Row>;
        }

        // Render children if no error occurred
        return this.props.children;
    }
}

export default ErrorBoundary;