

import moment from "moment";



/**
 * 
 * @param {function} columns 
 * @param {object} record 
 * @param {object} metaData 
 * @param {array} records 
 * @param {object} locationState 
 * @returns 
 */
export function resolveColumns(columns, role, record = {}, metaData = {}, records = [], locationState, sessionId) {
  const schema = typeof columns === 'function' ? columns(role, record, metaData, records, sessionId) : columns;
  const section = schema && typeof schema === 'object' && schema.section || false;
  const sectionColumns = section && locationState && locationState.schemaId && section.find(_ => _.schemaId === locationState.schemaId) || false;

  return (sectionColumns && sectionColumns.columns) || (schema && schema.columns) || schema;
}

export const dummyData = (fields) => Array.from(Array(10), (x, index) => {
  const columns = fields && fields.length > 0 && fields.reduce((k, el) => Object.assign({}, k, { [el.value]: '' }), {})
  return Object.assign({}, columns, { id: index + 1, loading: true })
})

/**
 * @param {array} columns 
 */
export function set(columns, formRecord = {}) {
  if (!columns && !formRecord) {
    return false;
  }
  let Columns = columns && Object.assign([], columns);
  return Columns.reduce((a, el) => el.defaultValue ? Object.assign({}, a, { [el.value]: el.defaultValue }) : a, formRecord);
}


/**
 * 
 * @param {integer} bytes 
 * @param {integer} decimals 
 * @returns 
 */
export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes"
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}


/**
 * 
 * @param {string} name 
 * @returns 
 */
 export function getContentType(name) {
  const extension = name && name.substring(name.lastIndexOf('.') + 1).toLowerCase() || '';
  if (extension === 'pdf') {
    return 'application/pdf'
  } else if (extension === 'mp4') {
    return 'video/mp4'
  } else if (extension === 'gif') {
    return 'image/gif'
  } else if (extension === 'png') {
    return 'image/png'
  } else if (extension === 'ico') {
    return 'image/x-icon'
  } else if (extension === 'jng') {
    return 'image/x-jng'
  } else if (extension === 'bmp') {
    return 'image/x-ms-bmp'
  } else if (extension === 'webp') {
    return 'image/webp'
  } else if (extension === 'wbmp') {
    return 'image/vnd.wap.wbmp'
  } else if (extension === 'jpeg' || extension === 'jpg') {
    return 'image/jpeg'
  } else if (extension === 'svg' || extension === 'svgz') {
    return 'image/svg+xml'
  } else if (extension === 'tif' || extension === 'tiff') {
    return 'image/tiff'
  } else if (extension === '3gpp' || extension === '3gp') {
    return 'video/3gpp'
  } else if (extension === 'mpeg' || extension === 'mpg') {
    return 'video/mpeg'
  } else if (extension === 'asx' || extension === 'asf') {
    return 'video/x-ms-asf'
  } else if (extension === 'mov') {
    return 'video/quicktime'
  } else if (extension === 'ogg') {
    return 'video/ogg'
  } else if (extension === 'wmv') {
    return 'video/x-ms-wmv'
  } else if (extension === 'webm') {
    return 'video/webm'
  } else if (extension === 'flv') {
    return 'video/x-flv'
  } else if (extension === 'avi') {
    return 'video/x-msvideo'
  } else if (extension === 'm4v') {
    return 'video/x-m4v'
  } else if (extension === 'mng') {
    return 'video/x-mng'
  } else if (extension === 'doc' || extension === 'dot') {
    return 'application/msword'
  } else if (extension === 'docx') {
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  } else if (extension === 'dotx') {
    return 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
  } else if (extension === 'docm') {
    return 'application/vnd.ms-word.document.macroEnabled.12'
  } else if (extension === 'xls') {
    return 'application/vnd.ms-excel'
  } else if (extension === 'xlsx') {
    return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  } else if (extension === 'xlsb') {
    return 'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
  } else if (extension === 'xlsm') {
    return 'application/vnd.ms-excel.sheet.macroEnabled.12'
  } else if (extension === 'csv') {
    return 'text/csv'
  } else {
    return extension;
  }
}


export function convertObjectToParams(obj) {
  var str = "";
  for (var key in obj) {
    if (str !== "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(obj[key]);
  }

  return str;
}


export function emptyfn (){
  return null;
}


export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}


export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}


/**
 * 
 * @param {date} start_date 
 * @param {date} end_date 
 * @returns 
 */
export function checkIsAfter (start_date, end_date) {
  return (start_date && end_date && moment(start_date).isAfter(end_date)) || false;
}


/**
 * 
 * @param {date} start_date 
 * @param {date} end_date 
 * @returns 
 */
export function checkIsBefore (start_date, end_date) {
  return (start_date && end_date && moment(start_date).isBefore(end_date)) || false;
}

/**
 * 
 * @param {date} date 
 * @returns 
 */
export function  setDateFormat(date) {
  return (date && moment(date).format('MM/DD/YYYY')) || moment().utcOffset(240).format('MM/DD/YYYY');
}

/**
 * 
 * @param {date} start_date 
 * @param {date} end_date 
 * @param {number} days 
 * @returns 
 */
export function checkTwoDates(start_date, end_date, days) {
  return (start_date && end_date && moment(end_date).diff(start_date, 'days') >= days) || false;
}

/**
 * 
 * @param {object} record 
 * @param {object} metaData 
 */
export function setLatLongFromVendor(record, metaData, sessionId) {
  if(!record && !metaData)
    return false;

  const selVendor = metaData?.vendors && metaData?.vendors.length > 0 && metaData?.vendors.find(_ => (record?.vendor_id && _.id === record?.vendor_id) || (_.id === sessionId));

  return (selVendor?.latitude && selVendor?.longitude && `${selVendor.latitude},${selVendor.longitude}`) || false

}


/**
 * 
 * @param {string} phone_no 
 * @returns 
 */
export function removeCountryCodeFromPhoneNo(phone_no) {
  if(!phone_no)
    return '';

  const allowedCountryCodes = ['971', '91', '33'];
  const selCountryCode = allowedCountryCodes?.length > 0 && allowedCountryCodes.find(code => phone_no.indexOf(code) > -1);
  return selCountryCode && phone_no.substring(selCountryCode.length) || '';
}



/**
 * 
 * @param {string} phone_no 
 * @returns 
 */
export function getCountryCodeFromPhoneNo(phone_no) {
  if(!phone_no)
    return '';

  const allowedCountryCodes = ['971', '91', '33'];
  const selCountryCode = allowedCountryCodes?.length > 0 && allowedCountryCodes.find(code => phone_no.indexOf(code) > -1);
  return (selCountryCode && `+${selCountryCode}`) || '';
}


/**
 * 
 * @param {string} post_categories_id 
 * @param {object} metaData 
 * @returns 
 */
export function getKeywordFromCategories(post_categories_id, { postCategories }) {
  if (!post_categories_id && !postCategories)
    return false;

  if (post_categories_id && postCategories && Array.isArray(postCategories) && postCategories.length > 0) {
    let keywords = [];
    let categoryId = post_categories_id;

    for (var i = 1; i <= 4; i++) {
      if (categoryId) {
        let postCategory = postCategories.find(_ => _.id === categoryId)
        if(postCategory){
          keywords.push(postCategory?.keyword)
          if (postCategory?.parent)
            categoryId = postCategory?.parent;
          else
            categoryId = false;
        }
      }
    }

    if(keywords && keywords.length > 0){
      return keywords.join(', ');
    }
  }

    return false;
}

/**
 * 
 * @param {string} inputString 
 * @returns 
 */
export function removeSpecialCharacters(inputString) {
  // Use regex to remove all characters that are not alphanumeric or whitespace
  return inputString.replace(/[^\w\s]/g, '');
}