import axios from 'axios'


const apiInstance = {
  baseURL: global.config.API_URL,
  timeout: 100000,
  headers: { Accept: 'application/json' },
};

console.log('the api instance are ', apiInstance);

const api = axios.create(apiInstance);

export function setAuthToken(authToken) {
  api.defaults.headers.common['Authorization'] = authToken
}

export function removeAuthToken() {
  api.defaults.headers.common['Authorization'] = ''
}

export default api;
