/**
 * 
 * Redirect
 * 
 */

import React, { Component } from "react"
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import SquareLoader from 'react-spinners/SquareLoader'

class Redirect extends Component {
    /**
     * Redirect to login
     */
    componentDidMount() {
        // emit the event
       setTimeout(() => {
        this.props.navigate(this.props.redirectPath || '/')
       }, 2000)
    }

    render() {
        return <div className="route_loader">
            <SquareLoader color="#50CC89" />
        </div>
    }
}

Redirect.propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    location: PropTypes.object,
    loggedIn: PropTypes.bool,
    name: PropTypes.string,
    navigate: PropTypes.func,
    params: PropTypes.object,
    path: PropTypes.string,
    user: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool
    ])
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    }
}

export default connect(null, mapDispatchToProps)(Redirect)
