/*
 *
 * Session reducer
 *
 */

import { produce } from 'immer';

import {
  VERIFY_SESSION_SUCCESS,
  VERIFY_SESSION_ERROR,
  LOG_IN_SUCCESS,
  LOG_OUT_SUCCESS,
  LOG_IN_ERROR,
  LOG_OUT_ERROR,
  FORGOT_PASSWORD_ERROR,
  FORGOT_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
  VERIFY_OTP_ERROR,
  VERIFY_OTP_SUCCESS,
  NOTIFICATION_SUCCESS,
  CLEAR_CACHE
} from './constants';


const initialState = {
  loading: true,
  error: false,
  success: false,
  loggedIn: false,
  user: false,
  secret: false,
  notification: [],
  version: '1.0'
};

/**
 * @param {object} state 
 * @param {object} action 
 */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case VERIFY_SESSION_SUCCESS:
        draft.loggedIn = true;
        draft.user = action.user;
        draft.loading = false;
        break;
      case LOG_IN_SUCCESS:  
      case VERIFY_OTP_SUCCESS:
        draft.loggedIn = true;
        draft.secret = action.token;
        draft.error = false;
        draft.success = false;
        draft.loading = false;
        break;
      case VERIFY_SESSION_ERROR:
      case LOG_IN_ERROR:
      case VERIFY_OTP_ERROR:
      case FORGOT_PASSWORD_ERROR:
      case CHANGE_PASSWORD_ERROR:
        draft.loggedIn = false;
        draft.user = false;
        draft.error = action.error;
        draft.success = false;
        draft.loading = false;
        break;
      case LOG_OUT_ERROR:
      case LOG_OUT_SUCCESS:
        draft.loggedIn = false;
        draft.user = false;
        draft.error = false;
        draft.success = false;
        draft.loading = false;
        break;
      case FORGOT_PASSWORD_SUCCESS:
      case CHANGE_PASSWORD_SUCCESS: ;
        draft.error = false
        draft.success = action.success;
        draft.loading = false;
        break;
      case NOTIFICATION_SUCCESS:
        draft.notification = action.records;
        break;
      case CLEAR_CACHE:
        draft.loggedIn = false;
        draft.user = false;
        draft.error = false;
        draft.success = false;
        draft.loading = false;
        break;
    }
  });


export default appReducer;
